<template>
  <div class="trainingPage">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center">Welcome Aboard Watch This First</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446516893?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Agency Dashboard Overview</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446517579?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Agency White Label Custom URL Training</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446519077?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Creating A Business Account</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446532590?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Setting Up Your First Campaign</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446535669?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Installing The Widget On A Website</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446531140?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Redemption Page Training</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446538958?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Campaign Stats Overview</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446540538?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">Contacts Overview</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446544870?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-12 wrapper">
        <h1 class="text-center">SMS Broadcast Overview</h1>
        <div class="videoWrapper mt-4">
          <iframe
            src="https://player.vimeo.com/video/446546947?autoplay=false&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.trainingPage {
  .wrapper {
    margin-top: 100px;
  }
  .videoWrapper {
    height: 400px;
    max-width: 680px;
    width: 90vw;
    margin: auto;
    position: relative;
    // border:15px solid #333;
    // -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
    // box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
    // border-radius: 10px;
  }
}
</style>
